import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Board } from "shared/datamodel/schemas/board";
import { BoardCard } from "./board-card";
import { BatchedItemsGrid } from "./batched-items-grid";
import NoCanvasesEmptystate from "./no-canvases-empty-state";
import { CanvasTemplate } from "shared/datamodel/schemas/canvas-template";
import style from "./boards-by-section.module.css";
import { navigateToBoard } from "./boards-grid-utils";
import { uniqBy, prop } from "rambda";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { useSortedBoards } from "subscriptions";
import { useAccountReps } from "frontend/hooks/use-account-reps";
import useStateValue from "frontend/state/value";
import classNames from "classnames";
import useBoards from "frontend/hooks/use-boards";

export enum BoardSectionType {
  PROJECT = "PROJECT",
  TEAM = "TEAM",
  MY_BOARDS = "MY_BOARDS",
}

export interface BoardSection {
  id: number;
  name: string;
  type: BoardSectionType;
}

export default function BoardsBySection({
  section,
  onSelected,
  createNewBoard,
  onDuplicateError,
  setCanvasToMove,
  userId,
  accountId,
}: {
  section: BoardSection;
  onSelected: (documentId: string) => void;
  createNewBoard: (template: Omit<CanvasTemplate, "data"> & { data?: Record<string, unknown> }) => void;
  onDuplicateError: (number: number) => void;
  setCanvasToMove: (board: Board | null) => void;
  userId?: string;
  accountId?: string;
}) {
  const { accountRepsInstance } = useAccountReps();
  const [{ boards }] = useStateValue();
  const allSortedBoards = useSortedBoards(accountRepsInstance, boards);
  const [filteredBoards, setFilteredBoards] = useState<Board[]>([]);
  const eventSent = React.useRef(false);
  const { isLoading: isLoadingBoards } = useBoards();

  const filterBoardsBySection = React.useCallback(() => {
    const filterStrategies = {
      [BoardSectionType.PROJECT]: () =>
        allSortedBoards.filter((board) => board.projectId && board.projectId === section?.id),
      [BoardSectionType.TEAM]: () =>
        allSortedBoards.filter((board) => board.teamId && board.teamId === section?.id && !board.projectId),
      [BoardSectionType.MY_BOARDS]: () =>
        uniqBy(
          prop("documentId"),
          allSortedBoards.filter((board) => board.isOwner)
        ),
    };

    setFilteredBoards(filterStrategies[section.type]());
  }, [allSortedBoards, section]);

  useEffect(() => {
    if (!allSortedBoards) return;

    if (!eventSent.current) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, "boards_page_view", accountId, userId);
      eventSent.current = true;
    }
    filterBoardsBySection();
  }, [allSortedBoards, section.id, section.name, accountId, userId, filterBoardsBySection]);

  const renderCard = (board: Board, index: number) => {
    return (
      <BoardCard
        onClick={() => {
          onSelected(board.documentId);
          navigateToBoard(board);
        }}
        board={board}
        key={board.id}
        showDynamicPreview={false}
        board_index={index}
        onDuplicateError={onDuplicateError}
        setCanvasToMove={setCanvasToMove}
      />
    );
  };

  const renderBoardsEmptyState = () => {
    const emptyStateMessages = {
      [BoardSectionType.PROJECT]: "No canvases in this project yet!\n Create a new canvas for this project",
      [BoardSectionType.TEAM]: "No canvases in this team yet!\n Create a new canvas for this team",
      [BoardSectionType.MY_BOARDS]: "No canvases yet!\n Create your first canvas",
    };

    return <NoCanvasesEmptystate subtitle={emptyStateMessages[section.type]} createNewBoard={createNewBoard} />;
  };

  const renderSectionName = () => {
    const sectionNames = {
      [BoardSectionType.PROJECT]: `Canvases in "${section.name}" project`,
      [BoardSectionType.TEAM]: `Canvases in "${section.name}" team`,
      [BoardSectionType.MY_BOARDS]: "My Canvases",
    };
    return sectionNames[section.type];
  };

  const renderContent = () => {
    if (!filteredBoards || isLoadingBoards) {
      return (
        <>
          <Skeleton count={1} className={classNames(style.titleLine, style.titleLineSkeleton)} />
          <Skeleton count={10} className={style.skeletonCard} containerClassName={style.skeletonCardContainer} />
        </>
      );
    }

    if (filteredBoards.length === 0) {
      return renderBoardsEmptyState();
    }

    return (
      <div className={style.section}>
        <span className={style.titleLine}>{renderSectionName()}</span>
        <BatchedItemsGrid
          items={filteredBoards}
          gridContainerClass={style.boardsGrid}
          cellRender={renderCard}
          batchSize={50}
        />
      </div>
    );
  };

  return <div className={style.boardsContainer}>{renderContent()}</div>;
}
